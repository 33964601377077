import ls from 'localstorage-slim';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import RadioContainer from '../../../b2b/components/radio-container/radio-container';
import Radio from '../../../b2b/components/radio/radio';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';

const HasCoApplicant: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Histroy Hook
  const navigate = useNavigate();

  // Dispatch Hook
  const dispatch = useAppDispatch();

  // Navigation
  const { nextRoute } = new Navigation();

  const getLocalHp = ls.get('hp');

  const [valueHP, setValueHP] = useState<boolean | undefined>(
    getLocalHp === 'true' || false,
  );

  const [loading, setLoading] = useState<boolean>(false);

  // Handle checkbox changes
  const handleChangeCheckboxHP = (selection: boolean) => () => {
    setValueHP(selection);
  };

  /**
   * saves value in local storage as (hp)
   * if the user select yes goes to co-applicant page
   * is no removes co-applicant from sequence(check sequence-conditions.ts) goes to auth rep page
   */
  const handleSubmit = async () => {
    setLoading(true);
    if (valueHP) {
      ls.set('hp', 'true');
    } else {
      ls.set('hp', 'false');
    }
    try {
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            is_co_applicant_present: valueHP,
          },
          path: APIPaths.UPDATE_MAIN_SIGNER,
        }),
      )
        .then(() => {
          setLoading(false);
          navigate(nextRoute());
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    } catch (e: any) {
      setLoading(false);
      errorLogging(e);
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Das tut uns leid.',
          notificationBody: getText('error_unable_to_process_data'),
        }),
      );
    }
  };

  return (
    <Layout
      heading='Möchtest du einen Mitantragsteller hinzuzufügen?'
      stepStatus
      subHeading={getText('has_partner_subTitle')}
      className='choice-modal'
    >
      <div
        className='template-body '
        style={{ display: 'flex', flexDirection: 'column', marginTop: '48px' }}
      >
        <span className='content-bold center'>
          Jetzt Mitantragssteller hinzufügen:
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '48px',
          }}
          className='template-body '
        >
          <RadioContainer>
            <Radio
              checked={valueHP === true}
              onChange={handleChangeCheckboxHP(true)}
            >
              <p className='content-normal'>Ja</p>
            </Radio>
            <Radio
              checked={valueHP === false}
              onChange={handleChangeCheckboxHP(false)}
            >
              <p className='content-normal'>Nein</p>
            </Radio>
          </RadioContainer>
        </div>
      </div>

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'button',
          loading,
          onClick: handleSubmit,
          name: 'form-submit',
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default HasCoApplicant;
