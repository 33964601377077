import LayerOnePhaseOne from '../../../../styles/animated/phase_one_image/Layer_1.svg';
import LayerTwoPhaseOne from '../../../../styles/animated/phase_one_image/Layer_2.svg';
import LayerThreePhaseOne from '../../../../styles/animated/phase_one_image/Layer_3.svg';
import LayerFourPhaseOne from '../../../../styles/animated/phase_one_image/Layer_4.svg';
import '../animation.css';

const CreditCheckLoader = () => {
  return (
    <div className='animation-layout margin-auto'>
      <img src={LayerOnePhaseOne} alt='animation' className='animate' />
      <img src={LayerTwoPhaseOne} alt='animation' className='animate' />
      <img src={LayerTwoPhaseOne} alt='animation' className='animate' />
      <img src={LayerThreePhaseOne} alt='animation' className='animate' />
      <img src={LayerFourPhaseOne} alt='animation' className='animate' />
    </div>
  );
};

export default CreditCheckLoader;
