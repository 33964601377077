import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import countries from '../../../../localization/countries.json';
import { Signer, SingersType } from '../../../../types/instant-lease-api';
import { getCountryByCode, getText } from '../../../../utils/getter';
import { mapAddressField } from '../../../../utils/helper';
import Button from '../../../b2b/components/button/button';
import RadioContainer from '../../../b2b/components/radio-container/radio-container';
import Radio from '../../../b2b/components/radio/radio';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import InputDate from '../../../common/input-field/input-date';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import TwoInputFields from '../../../common/two-input-fields/two-input-fields';
import './personal-template.css';

export interface PersonalData {
  uuid?: string;
  first_name: string;
  last_name: string;
  gender: string;
  date_of_birth: string;
  birth_place: string;
  citizenship: string;
  street_line: string;
  house_number: string;
  additional_lines?: string;
  postal_code: string;
  locality: string;
  country_code: string;
  oc_number?: string;
  type?: any;
}

interface PersonalDataProps {
  hasSpecialField?: boolean;
  specialFieldTooltip?: string;
  fieldName?: string;
  ocNumber?: string | undefined;
  onSubmit: (owner: PersonalData) => void;
  tabButton: boolean;
  privateCustomer: Signer | undefined;
  loading?: boolean;
  singer?: boolean;
}

const PersonalTemplate: React.FC<PersonalDataProps> = ({
  hasSpecialField,
  specialFieldTooltip,
  fieldName,
  ocNumber,
  onSubmit,
  tabButton,
  privateCustomer,
  loading,
  singer = false,
}) => {
  // Gender values
  const genderCheckbox = [
    {
      value: 'GENDER_MALE',
      label: 'Herr',
    },
    {
      value: 'GENDER_FEMALE',
      label: 'Frau',
    },
  ];

  // validationSchema
  const validationSchema = yup.object().shape({
    first_name: yup.string().required('Pflichtfeld'),
    last_name: yup.string().required('Pflichtfeld'),
    date_of_birth: yup
      .string()
      .required('Pflichtfeld')
      .matches(
        /^(((0?[1-9]|[12]\d|3[01])\.(0[13578]|[13578]|1[02])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|[12]\d|30)\.(0[13456789]|[13456789]|1[012])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|1\d|2[0-8])\.0?2\.((1[6-9]|[2-9]\d)\d{2}))|(29\.0?2\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
        'Bitte Geburtsdatum eintragen (TT.MM.JJJJ)',
      )
      .test({
        name: 'test-age',
        message: getText('18_years_validation_message'),
        test: (age) => moment().year() - moment(age, 'DD.MM.YYYY').year() >= 18,
      }),
    birth_place: yup.string().required('Pflichtfeld'),
    gender: yup.string().nullable().required('Bitte wähle eine Anrede aus'),
    street_line: yup.string().required('Pflichtfeld'),
    house_number: yup.string().required('Pflichtfeld'),
    postal_code: yup.string().required('Pflichtfeld'),
    locality: yup.string().required('Pflichtfeld'),
    country_code: yup.string().required('Pflichtfeld'),
    citizenship: yup.string().required('Pflichtfeld'),
    oc_number: hasSpecialField
      ? yup.string().required('Pflichtfeld')
      : yup.string().optional(),
    type: singer
      ? yup.boolean().nullable().required('Pflichtfeld')
      : yup.boolean().nullable().optional(),
  });

  // Form hook
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
    watch,
  } = useForm<PersonalData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: privateCustomer?.person?.first_name,
      last_name: privateCustomer?.person?.last_name,
      date_of_birth: privateCustomer?.person?.date_of_birth,
      birth_place: privateCustomer?.person?.birth_place,
      gender: privateCustomer?.person?.gender,
      citizenship: privateCustomer?.person?.citizenship,
      street_line: mapAddressField(
        privateCustomer?.person?.contact_details,
        'street_line',
      ),
      house_number: mapAddressField(
        privateCustomer?.person?.contact_details,
        'house_number',
      ),
      additional_lines: mapAddressField(
        privateCustomer?.person?.contact_details,
        'additional_lines',
      ),
      postal_code: mapAddressField(
        privateCustomer?.person?.contact_details,
        'postal_code',
      ),
      locality: mapAddressField(
        privateCustomer?.person?.contact_details,
        'locality',
      ),
      country_code:
        (privateCustomer?.person?.contact_details?.addresses &&
          privateCustomer?.person?.contact_details?.addresses.length &&
          getCountryByCode(
            privateCustomer?.person?.contact_details?.addresses[0].country_code,
          )?.value) ||
        'DEU',
      oc_number: ocNumber,
      type: privateCustomer?.type === SingersType.CO_APPLICANT_IN_PARTNERSHIP,
    },
  });

  useEffect(() => {
    if (privateCustomer?.type !== undefined) {
      setValue(
        'type',
        privateCustomer?.type === SingersType.CO_APPLICANT_IN_PARTNERSHIP,
      );
    }
  }, [privateCustomer?.type, setValue]);

  // Submit Data
  const submitData = ({
    first_name,
    last_name,
    gender,
    date_of_birth,
    birth_place,
    citizenship,
    street_line,
    additional_lines,
    postal_code,
    locality,
    country_code,
    house_number,
    oc_number,
    type,
  }: PersonalData) => {
    if (privateCustomer?.uuid) {
      onSubmit({
        uuid: privateCustomer?.uuid,
        first_name,
        last_name,
        date_of_birth,
        birth_place,
        citizenship,
        gender,
        street_line,
        additional_lines,
        postal_code,
        locality,
        country_code,
        house_number,
        oc_number,
        type: type
          ? SingersType.CO_APPLICANT_IN_PARTNERSHIP
          : SingersType.CO_APPLICANT,
      });
    } else {
      onSubmit({
        uuid: uuidv4(),
        first_name,
        last_name,
        date_of_birth,
        birth_place,
        citizenship,
        gender,
        street_line,
        additional_lines,
        postal_code,
        locality,
        country_code,
        house_number,
        oc_number,
        type: type
          ? SingersType.CO_APPLICANT_IN_PARTNERSHIP
          : SingersType.CO_APPLICANT,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitData)}>
        <div>
          <FormLayout sectionTitle='Persönliche Daten'>
            <div className='choice-box'>
              <div className='choice-selection'>
                <label
                  className='checkbox-label content-normal'
                  style={{ marginBottom: '24px' }}
                >
                  Anrede
                </label>
                <RadioContainer>
                  {genderCheckbox?.map(({ value, label }) => (
                    <Radio
                      key={label}
                      errorMessage={errors?.gender?.message}
                      value={watch('gender') || ''}
                      checked={watch('gender') === value}
                      onChange={(e: any) => {
                        setValue('gender', value);
                      }}
                    >
                      <span className='content-normal gender-option'>
                        {label}
                      </span>
                    </Radio>
                  ))}
                </RadioContainer>
              </div>
            </div>
            <InputField
              label='Vorname(n)'
              {...register('first_name')}
              error={errors.first_name?.message}
            />
            <InputField
              label='Nachname(n)'
              {...register('last_name')}
              error={errors.last_name?.message}
            />{' '}
            <InputDate
              label='Geburtsdatum'
              trigger={trigger}
              {...register('date_of_birth')}
              setValue={setValue}
              placeholder='TT.MM.JJJJ'
              error={errors.date_of_birth?.message}
            />
            <InputField
              label='Geburtsort'
              {...register('birth_place')}
              error={errors.birth_place?.message}
            />
            <SelectBox
              initialValue={watch('citizenship')}
              setValueCallBack={(value) =>
                setValue('citizenship', value, { shouldValidate: true })
              }
              {...register('citizenship')}
              label='Nationalität'
              options={countries}
              hasError={!!errors?.citizenship?.message}
              errorMessage={errors?.citizenship?.message}
              dataTestId='citizenship-select'
            />
            {hasSpecialField && (
              <InputField
                label={fieldName}
                tooltip={specialFieldTooltip}
                tooltipPosition='inline-block'
                {...register('oc_number')}
                error={errors.oc_number?.message}
              />
            )}
          </FormLayout>
          <FormLayout sectionTitle='Meldeadresse'>
            <TwoInputFields
              label='Straße | Hausnummer'
              gridTemplateColumns='70% 25% '
            >
              <InputField
                {...register('street_line')}
                error={errors?.street_line?.message}
              />
              <InputField
                {...register('house_number')}
                error={errors?.house_number?.message}
              />
            </TwoInputFields>
            <InputField
              label='Adresszusatz '
              extraLabel=' (optional)'
              {...register('additional_lines')}
              error={errors?.additional_lines?.message}
            />
            <TwoInputFields
              label='Postleitzahl | Ort'
              gridTemplateColumns='25% 70%'
            >
              <InputField
                {...register('postal_code')}
                error={errors.postal_code?.message}
              />
              <InputField
                {...register('locality')}
                error={errors.locality?.message}
              />
            </TwoInputFields>
            <SelectBox
              label='Land'
              initialValue={watch('country_code')}
              setValueCallBack={(value) =>
                setValue('country_code', value, { shouldValidate: true })
              }
              {...register('country_code')}
              name='country_code'
              options={Object.values(countries)}
              hasError={!!errors?.country_code?.message}
              errorMessage={errors?.country_code?.message}
            />

            {singer && (
              <div className='choice-box'>
                <div className='choice-selection'>
                  <RadioContainer subTitle='Lebst Du mit dem Antragsteller in einer Lebensgemeinschaft?'>
                    <Radio
                      value={watch('type') || ''}
                      checked={watch('type')}
                      onChange={(e: any) => {
                        setValue('type', true);
                      }}
                    >
                      <span className='content-normal gender-option'>Ja</span>
                    </Radio>
                    <Radio
                      value={watch('type') || ''}
                      checked={watch('type') === false}
                      onChange={(e: any) => {
                        setValue('type', false);
                      }}
                    >
                      <span className='content-normal'>Nein</span>
                    </Radio>
                  </RadioContainer>

                  <div className='choice-selection'>
                    <div className='empty' />
                    <p className='error sub-bold small-text margin-left-10'>
                      {!!errors?.type?.message && (
                        <p className='error small-text'>
                          {errors?.type?.message as any}
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {tabButton ? (
              <div className='add-additional-person'>
                <Button
                  theme='secondary'
                  type='button'
                  className='save-button next button-text save-and-next-tab'
                  role='presentation'
                  loading={loading}
                  onClick={handleSubmit(submitData)}
                >
                  nächster Schritt
                </Button>
              </div>
            ) : (
              <ButtonGroup
                type='back-next'
                className='back-next'
                buttonOneProps={{
                  type: 'submit',
                  name: 'form-submit',
                  loading,
                  dataTestId: 'start-fetching',
                  children: 'weiter',
                }}
              />
            )}
          </FormLayout>
        </div>
      </form>
    </div>
  );
};

export default PersonalTemplate;
