import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/types';
import sequenceConfig from '../sequence/sequence-config';
import Navigation from '../utils/navigation';
import { ReactComponent as BackArrow } from '../view/b2b/components/icons/arrow_back.svg';
import { ReactComponent as Help } from '../view/b2b/components/icons/help.svg';
import PartnerLogo from '../view/b2b/components/partner-logo/partner-logo';
import ProgressBar from '../view/b2b/components/progress-bar/progress-bar';

interface LayoutCommonProps extends React.HTMLAttributes<HTMLDivElement> {
  heading?: string;
  subHeading?: string;
  stepStatus?: boolean;
  logo?: boolean;
  tooltip?: string;
  customStepCurrent?: number;
  customStepMax?: number;
  widthAuto?: boolean;
}

const Layout: React.FC<LayoutCommonProps> = ({
  className = '',
  style,
  heading,
  subHeading,
  children,
  stepStatus,
  logo,
  tooltip,
  customStepCurrent,
  customStepMax,
  widthAuto,
  ...props
}) => {
  // Get current state
  const sequenceStore = useSelector((state: RootState) => state.sequence);

  // Sequence Config
  const seqConfig = sequenceConfig();

  // Navigate Hook
  const navigate = useNavigate();

  // Navigation class
  const { previousRoute } = new Navigation();

  // Current steps
  const currentSequenceSteps = seqConfig[sequenceStore.sequence]
    ? seqConfig[sequenceStore.sequence].steps
    : [];

  // Back button action
  const goBack = () => navigate(previousRoute());

  return (
    <div
      className={`layout ${
        widthAuto ? 'container-width-auto' : 'container'
      } ${className} ${subHeading ? 'hasSubHeading' : ''}`}
    >
      {!sequenceStore.isStatusOverviewOpen && (
        <BackArrow className='back-button pointer' onClick={() => goBack()} />
      )}

      <div
        className={`${
          !sequenceStore.isStatusOverviewOpen ? 'inner-container' : ''
        } `}
        style={style}
        {...props}
      >
        {logo && <PartnerLogo />}
        {stepStatus && (
          <ProgressBar sequence={sequenceStore} steps={currentSequenceSteps} />
        )}
        <div className='layout-header'>
          <h1 data-testid='heading' className='title'>
            {heading}
          </h1>

          {subHeading && (
            <p className='content-normal dark-grey'>{subHeading}</p>
          )}

          {tooltip && (
            <div className='hover-text'>
              <Help style={{ marginLeft: '5px' }} />
              <p className='tooltip-text'>{tooltip}</p>
            </div>
          )}
        </div>
        <div className='main-content'>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
